import {PortableText} from '@/modules/foundation/sanity/portable-text';

import {ServiceCard} from '../../components/service-card';

import type {ServiceCardFragment} from './query';

type Props = {
	value: ServiceCardFragment;
};

export const ServiceCardContainer = ({value}: Props) => {
	if (!value.service) {
		return null;
	}

	const {title, description} = value.service;

	return (
		<ServiceCard
			title={title ?? ''}
			description={description && <PortableText blocks={description} />}
		/>
	);
};
