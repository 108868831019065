import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';
import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';

import {cn} from '@/modules/foundation/shared/tailwind';

const paragraph = cva([], {
	variants: {
		size: {
			small: ['text-base'],
			medium: ['text-lg'],
			large: ['text-lg', 'md:text-xl'],
			xlarge: ['text-xl', 'md:text-2xl'],
			'2xlarge': ['text-2xl', 'md:text-3xl'],
			'3xlarge': ['text-3xl', 'md:text-4xl'],
			'4xlarge': ['text-4xl', 'md:text-5xl'],
		},
	},
	defaultVariants: {
		size: 'medium',
	},
});

type ParagraphProps = VariantProps<typeof paragraph> &
	ComponentPropsWithoutRef<'p'> & {
		/**
		 * Change the default rendered element for the one passed as a child, merging their props and behavior.
		 * @default false
		 */
		asChild?: boolean;
	};

/** Use `Paragraph` to display text with paragraph text styles. */
export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
	({size = 'medium', className, asChild, ...rest}, ref) => {
		const Component = asChild ? Slot : 'p';

		return <Component ref={ref} className={cn(paragraph({size, className}))} {...rest} />;
	},
);
Paragraph.displayName = 'Paragraph';
