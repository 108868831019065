import type {ReactNode} from 'react';
import clsx from 'clsx';

import {Heading} from '@/modules/foundation/components/typography/heading';

type Props = {
	title: string;
	description: ReactNode;
};

export const ServiceCard = ({title, description}: Props) => {
	return (
		<div className={clsx('bg-primary-light', 'p-4', 'rounded-xl', 'mb-4')}>
			<Heading size="large" spacing level={3}>
				{title}
			</Heading>
			<div>{description}</div>
		</div>
	);
};
